import { graphql } from 'gatsby';

import { Page } from '../components/layout';
import { Markdown } from '../components/utils';

import Hours from './location/hours';
import Teaser from './location/teaser';
import Contact from './location/contact';
import * as styles from './location.module.scss';

const Location = ({
	data: {
		markdownRemark: {
			frontmatter: { address, contact, images, hours, logo },
			fields: { slug },
			html,
		},
	},
}) => (
	<Page slug={slug} body={html} teaser={<Teaser logo={logo} images={images} />}>
		{Array.isArray(hours) && (
			<div className={styles.hours}>
				<Hours hours={hours} />
			</div>
		)}

		<Markdown content={address} className={styles.address} />

		<Contact contact={contact} />
	</Page>
);

export const locationQuery = graphql`
	query locationQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			fields {
				slug
			}
			frontmatter {
				images {
					childImageSharp {
						gatsbyImageData(width: 900, layout: CONSTRAINED)
					}
				}
				logo {
					publicURL
				}
				contact {
					email
					phone
					url
				}
				address
				hours {
					date
					time
				}
			}
			html
		}
	}
`;

export default Location;
