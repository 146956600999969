import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { t } from '../../utils/i18n';
import useBlockLocation from '../../hooks/use-block-location';

import * as styles from './hours.module.scss';

const Hours = ({ hours }) => {
	const { label_hours } = useBlockLocation();

	return (
		<Fragment>
			<h2>{label_hours || t('HOURS')}</h2>
			{hours.map(({ date, time }, key) => (
				<div key={key} className={styles.item}>
					<span className={styles.date}>{date}</span>
					<span>{time}</span>
				</div>
			))}
		</Fragment>
	);
};

Hours.defaultProps = {
	hours: [],
};

Hours.propTypes = {
	hours: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string.isRequired,
			time: PropTypes.string.isRequired,
		})
	).isRequired,
};

export default Hours;
