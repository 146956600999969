import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import Slider from '../../components/slider';
import { SVGIcon } from '../../components/icons';

import * as styles from './teaser.module.scss';

const Teaser = ({ images, logo: { publicURL: src } }) => {
	return (
		<Fragment>
			{Array.isArray(images) && (
				<Slider>
					{images.map((image, key) => (
						<GatsbyImage key={key} image={image.childImageSharp.gatsbyImageData} alt="" />
					))}
				</Slider>
			)}
			<SVGIcon path={src} className={styles.logo} />
		</Fragment>
	);
};

Teaser.defaultProps = {
	logo: null,
	images: [],
};

Teaser.propTypes = {
	logo: PropTypes.shape({
		publicURL: PropTypes.string.isRequired,
	}),
	images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default Teaser;
