import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PhoneIcon, EmailIcon } from '../../components/icons';
import { format, toUri } from '../../utils/phone';

import * as styles from './contact.module.scss';

const Contact = ({ contact: { phone, email, url } }) => {
	const phoneUri = toUri(phone);

	return (
		<Fragment>
			{phoneUri && (
				<a href={phoneUri} className={styles.item}>
					<PhoneIcon className={styles.icon} />
					{format(phone)}
				</a>
			)}

			<a href={`mailto:${email}`} className={styles.item}>
				<EmailIcon className={styles.icon} />
				{email}
			</a>

			{url && (
				<a href={url} target="_blank" className={styles.url}>
					{url}
				</a>
			)}
		</Fragment>
	);
};

Contact.defaultProps = {
	contact: null,
};

Contact.propTypes = {
	contact: PropTypes.shape({
		phone: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		url: PropTypes.string,
	}),
};

export default Contact;
